<template>
	<b-modal ref="modal-new-neighborhood" id="modal-new-neighborhood" hide-footer title="Nuevo Barrio">
		<b-row>
			<b-col>
				<b-form @submit.prevent="onSubmit" @reset="onReset">
					<form-data :form.sync="form" />
					<b-form-group class="text-right">
						<b-button type="reset" class="mr-2" variant="danger">Cerrar</b-button>
						<b-button type="submit" variant="primary">Guardar</b-button>
					</b-form-group>
				</b-form>
			</b-col>
		</b-row>
	</b-modal>
</template>
<script>
import EventBus from '../../modules/EventBus'

export default {
	data() {
		return {
			form: {}
		}
	},
	components: {
		'form-data': () => import('../Neighborhoods/Form')
	},
	methods: {
		onSubmit() {
			this.$http.crearBarrio(this.form)
			.then(res => {
				this.notifySuccess(res.data.message, 'Barrios')
				this.$root.$emit('bv::hide::modal', 'modal-new-neighborhood');
				this.form = {}
				EventBus.$emit('getNeigborhoods')
			})
		},
		onReset() {
			this.$root.$emit('bv::hide::modal', 'modal-new-neighborhood');
		}
	},
}
</script>